import React from 'react'
import { Route as RouterRoute, RouteProps as RouterRouteProps, RouteComponentProps } from 'react-router-dom'

import { RouteComponentWrapper } from '../'

export type ForceRenderParams<T> = Pick<RouteComponentProps<T>, 'location' | 'match'>

// Disallow passing custom render method
interface Props<T> extends Omit<RouterRouteProps, 'render'> {
  forceRender?(params: ForceRenderParams<T>): boolean
}

const Route = <T extends {}>({ component, forceRender, ...props }: Props<T>) =>
  component ? (
    <RouterRoute
      {...props}
      render={innerProps => (
        <RouteComponentWrapper
          {...innerProps}
          component={component}
          force={forceRender ? forceRender(innerProps) : false}
        />
      )}
    />
  ) : (
    <RouterRoute {...props} />
  )

Route.displayName = 'Route'
export default Route
