import React, { ReactNode } from 'react'
import { MemoryRouter } from 'react-router-dom'
import { MemoryRouterProps } from 'react-router'

interface TestWrapperProps {
  useRouter?: boolean
  routerInitialEntries?: string[]
  routerInitialIndex?: number
  children?: ReactNode
}

const RouterTestWrapper = ({
  useRouter,
  children,
  initialEntries,
  initialIndex
}: Partial<MemoryRouterProps> & {
  useRouter?: boolean
  children: ReactNode
}) => {
  if (useRouter !== false && initialEntries) {
    return (
      <MemoryRouter initialEntries={initialEntries} initialIndex={initialIndex}>
        {children}
      </MemoryRouter>
    )
  }

  return <React.Fragment>{children}</React.Fragment>
}

RouterTestWrapper.displayName = 'RouterTestWrapper'

const TestWrapper = ({
  useRouter,
  routerInitialEntries = ['/'],
  routerInitialIndex = 0,
  children
}: TestWrapperProps) => {
  return (
    <RouterTestWrapper useRouter={useRouter} initialEntries={routerInitialEntries} initialIndex={routerInitialIndex}>
      {children}
    </RouterTestWrapper>
  )
}

TestWrapper.displayName = 'TestWrapper'

export default TestWrapper
export { RouterTestWrapper }
