import styled from 'styled-components'
import { Container, spacingToRem, Typography } from '@bite/sandy'

const PageWrapper = styled(Container)`
  height: 100%;
  padding: ${props => spacingToRem(props.theme.layout.page.spacing, props.theme.spacing, true)};
  padding-top: 5px;
`

const PageContent = styled(Container)`
  flex-grow: 1;
  flex-direction: column;
  max-width: ${props => props.theme.layout.page.containerWidth}px;
`

const PageTitle = styled(Typography)`
  font-size: 36px;
`

export { PageWrapper, PageContent, PageTitle }
