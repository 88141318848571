import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

interface Props<T> extends RouteComponentProps<T> {
  force?: boolean
  component: React.ComponentType<RouteComponentProps<T>>
}

interface State {
  key?: string
}

/**
 * A wrapper for a component rendered by <Route>
 * It allows to forcefully update the component on demand by flipping the
 * `force` prop to true.
 *
 * The forced re-render is done by providing the `key` property to the component.
 * The key is remembered and used in case of flipping the `force` prop back to `false`.
 * This way we don't re-render component on the next navigation.
 */
class RouteComponentWrapper<T> extends React.Component<Props<T>, State> {
  static displayName = 'RouteComponentWrapper'

  public static getDerivedStateFromProps<T>(nextProps: Props<T>, prevState: State) {
    return {
      key: nextProps.force ? nextProps.location.key : prevState.key
    }
  }

  public state = {
    key: undefined
  }

  public render() {
    const { component, ...props } = this.props
    const { key } = this.state

    return React.createElement(component, {
      ...props,
      key
    })
  }
}

export default RouteComponentWrapper
