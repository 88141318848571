import React from 'react'

import * as S from './styles'

const PageLoader = () => (
  <S.PageLoader>
    <div />
  </S.PageLoader>
)

PageLoader.displayName = 'PageLoader'
export default PageLoader
