const prefix = 'alsdkjhoi849ruhkjmnpwif'
const postfix = 'poijkjrtnhfghivucxgbhmjjw984ihrkjnfg'

export const saveUser = (firstName: string, lastName: string, email: string, university?: string) => {
  const user = {
    firstName,
    lastName,
    email,
    university
  }

  // window.sessionStorage.setItem('qa-bt', `${prefix}${window.btoa(JSON.stringify(user))}${postfix}`)
  window.sessionStorage.setItem('qa-bt', `${prefix}${JSON.stringify(user)}${postfix}`)
}

export const getUser = () => {
  const hash = window.sessionStorage.getItem('qa-bt')
  if (!hash) return false

  const cleanedHash = hash.substring(prefix.length).slice(0, -postfix.length)
  // const user = JSON.parse(window.atob(cleanedHash))
  const user = JSON.parse(cleanedHash)
  if (user.firstName && user.lastName && user.email) {
    return user
  }
  return false
}
