import React from 'react'
import { Typography } from '@bite/sandy'

import { Page } from '../../components'

const TestPage = () => (
  <Page title='Quizzes' headerTitle='Quizzes' pageTitle='About'>
    <Typography variant='heading' size='xlarge' font='secondary'>
      Who we are?
    </Typography>
    <Typography size='medium' color='primary'>
      Graviti Q was created to allow you to get a better understanding of yourself and enable you to make happier &
      smarter decisions… and have a little fun while doing all that 😁
    </Typography>
  </Page>
)

TestPage.displayName = 'TestPage'
export default TestPage
