/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-params */
/* eslint-disable @typescript-eslint/no-unused-vars, react/display-name */
import React, { useState, useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import axios from 'axios'
import { Container, Typography, Grid, Button, useNotifications, Input, Modal, getBPHelpers } from '@bite/sandy'
import { Formik, Field } from 'formik'
import { ThemeContext } from 'styled-components'

import { Page } from '../../components'
import { saveUser, getUser } from '../../lib/userStorage'
import * as S from './styles'
import iPad from '../../assets/img/ipad.png'

const UserPage = (props: RouteComponentProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { showSuccess } = useNotifications()
  const themeContext = useContext(ThemeContext)
  const BPHelpers = getBPHelpers(themeContext.breakpoints)
  const spacesScreens = BPHelpers.useScreens<'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'>()

  const textTopSpacing = spacesScreens(
    {
      small: 'xlarge'
    },
    undefined
  )

  const textBottomSpacing = spacesScreens(
    {
      small: undefined
    },
    'xlarge'
  )

  const user = getUser()
  const handleSubmit = (values: { [key: string]: string }) => {
    saveUser(values.firstName, values.lastName, values.email, values.university)
    showSuccess('Užívateľské info uložené!')

    if (!user) {
      const postData = {
        user: {
          name: values.firstName,
          lastName: values.lastName,
          email: values.email,
          university: values.university
        }
      }

      axios
        .post('https://service.bite.social/quiz.php', postData, {
          headers: { token: 'lkjaj023ujrioljlsfkj02ij3kdlkjfoij029jkjlksaaijkldafadas' }
        })
        .then(res => res)
        .catch(error => error)

      // eslint-disable-next-line fp/no-mutating-methods
      props.history.push('/')
    }
  }

  const generateInfoRow = (num: number, title: string, text?: string) => (
    <Grid spacing='xsmall' alignItems='center'>
      <Grid.Item small={2}>
        <Typography variant='heading' size='large' font='secondary' color='white' align='right'>
          {num}.
        </Typography>
      </Grid.Item>
      <Grid.Item small={10}>
        <Typography size='small' color='blue' variant='heading' weight='bold'>
          {title}
        </Typography>
      </Grid.Item>
      {text && (
        <React.Fragment>
          <Grid.Item small={2} />
          <Grid.Item small={10}>
            <Container bottom='small'>
              <Typography size='medium' color='white'>
                {text}
              </Typography>
            </Container>
          </Grid.Item>
        </React.Fragment>
      )}
    </Grid>
  )

  return (
    <Page title='Užívateľská Zóna' headerTitle='Quizy'>
      <Container flex fullWidth direction='column'>
        <Container flex fullWidth direction='column' bottom={textBottomSpacing} top={textTopSpacing}>
          <S.OrangePaper>
            <Container padded='large'>
              <Typography size='xlarge' align='center' color='white' weight='semibold'>
                Staň sa súčasťou študentskej revolúcie a pomôž vyvinúť skvelú appku pre študentov
              </Typography>
              <Typography size='xlarge' align='center' color='white'>
                Sme tím mladých ľudí s cieľom zlepšiť študentský život a potrebujeme tvoju pomoc !!
              </Typography>
            </Container>
          </S.OrangePaper>
        </Container>
        <Container>
          <Grid direction='row-reverse'>
            <Grid.Item small={12} medium={6}>
              <Container fullWidth top='xlarge' bottom='xlarge'>
                <Typography variant='heading' size='large' font='secondary' align='center'>
                  INFO
                </Typography>
                <Typography size='medium' align='center'>
                  {user ? 'Vaše aktuálne užívateľské údaje' : 'Skôr než začneme, vyplň prosím par údajov'}
                </Typography>
              </Container>
              <Container fullWidth flex top='large'>
                <Formik
                  onSubmit={values => {
                    handleSubmit(values)
                  }}
                  initialValues={{
                    firstName: user.firstName || '',
                    lastName: user.lastName || '',
                    email: user.email || '',
                    university: user.university || ''
                  }}
                >
                  <S.UserForm>
                    <Container fullWidth flex bottom='medium'>
                      <Field
                        name='firstName'
                        id='fisrtName'
                        placeholder='Meno'
                        as={Input}
                        width='full'
                        inputProps={{ required: true }}
                      />
                    </Container>

                    <Container fullWidth flex bottom='medium'>
                      <Field
                        name='lastName'
                        id='lastName'
                        placeholder='Priezvisko'
                        as={Input}
                        width='full'
                        inputProps={{ required: true }}
                      />
                    </Container>

                    <Container fullWidth flex bottom='medium'>
                      <Field
                        name='email'
                        id='email'
                        placeholder='Email'
                        as={Input}
                        type='email'
                        width='full'
                        inputProps={{ required: true }}
                      />
                    </Container>

                    <Container fullWidth flex bottom='medium'>
                      <Field
                        name='university'
                        id='university'
                        placeholder='Univerzita a Fakulta'
                        as={Input}
                        width='full'
                      />
                    </Container>

                    <Container fullWidth flex bottom='xlarge' justifyContent='center' direction='row'>
                      <Typography inline color='orange' as='p' size='small' align='center'>
                        Tvoje dáta sú u nás v bezpečí, žiadne tvoje údaje nebudú poskytnuté tretím stranám &nbsp;
                        <Typography
                          color='orange'
                          inline
                          as='span'
                          size='small'
                          align='center'
                          weight='semibold'
                          onClick={e => {
                            e.stopPropagation()
                            setIsOpen(true)
                          }}
                          link
                        >
                          (pokračovaním súhlasíš s podmienkami, prečítaj si viac tu)
                        </Typography>
                      </Typography>
                      <Modal
                        size='medium'
                        open={isOpen}
                        closeHandler={() => setIsOpen(false)}
                        title='Podmienky a pravidlá používania'
                      >
                        <Typography size='medium'>
                          Verzia 1.0
                          <br />
                          Aktualizované 10. 11. 2020
                        </Typography>

                        <Container top='medium'>
                          <Typography variant='heading' size='medium' weight='medium'>O čo ide?</Typography>
                          <Typography size='medium'>
                            Tento dokument zdôrazňuje osobné informácie, ktoré spracovávame na podporu produktov a
                            funkcií ponúkaných spoločnosťou Bite. Inými slovami, kde určujeme účely a prostriedky
                            spracúvania vašich osobných údajov.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            Náš sľub
                          </Typography>
                          <Typography size='medium'>
                            Zaviazali sme sa k ochrane osobných údajov už v štádiu návrhu, čo znamená, že ochrana
                            súkromia našich používateľov je štandardne zahrnutá do našich systémov a technológií.
                          </Typography>
                        </Container>

                        <Container top='medium'>
                          <Typography variant='heading' size='medium' weight='medium'>
                            Aké údaje zbierame?
                          </Typography>
                          <Typography size='medium'>
                            Aby sme mohli poskytovať naše produkty a služby, musíme o vás spracovávať informácie. To,
                            aké údaje zhromažďujeme, závisí od toho, ako používate naše produkty a služby.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            Registrácia
                          </Typography>
                          <Typography size='medium'>
                            Pri registrácii zhromažďujeme nasledujúce údaje - vaše celé meno a e-mailovú adresu,
                            univerzitu a fakultu, užívateľské meno a heslo.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            Dotazníky
                          </Typography>
                          <Typography size='medium'>
                            Keď vyplňujete dotazníky - zhromažďujeme vaše odpovede a ukladáme ich do našej databázy.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            Údaje o použití
                          </Typography>
                          <Typography size='medium'>
                            Keď vstúpite na našu webovú stránku (a akceptujete naše súbory cookie), spracovávame údaje o
                            vašom používaní našej webovej stránky a služieb.
                          </Typography>
                          <Typography size='medium'>
                            Patria sem - vaša IP adresa, geografická poloha, typ a verzia prehliadača, operačný systém,
                            zdroj sprostredkovania, dĺžka návštevy, zobrazenia stránok a navigačné cesty webových
                            stránok, ako aj informácie o načasovaní, frekvencii a štruktúre využívania vašej služby.
                            Zdrojom údajov o využití je náš analytický sledovací systém - Google Analytics. Tieto údaje
                            o použití budú spracované na účely analýzy používania webových stránok a služieb.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            Údaje o účte
                          </Typography>
                          <Typography size='medium'>Spracovávame informácie o vašom účte.</Typography>
                          <Typography size='medium'>
                            Patria sem - vaše celé meno a e-mailová adresa, univerzita a fakulta, meno používateľa a
                            heslo.
                          </Typography>
                          <Typography size='medium'>
                            Údaje o účte sa spracúvajú na účely prevádzkovania našej webovej stránky, poskytovania
                            našich služieb, zaistenia bezpečnosti našej webovej stránky a služieb, udržiavania záloh
                            našich databáz a komunikácie s vami.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            Údaje z kvízu
                          </Typography>
                          <Typography size='medium'>
                            Spracovávame vaše kvízové ​​odpovede - „kvízové ​​údaje“.
                          </Typography>
                          <Typography size='medium'>
                            Údaje z kvízu sa spracúvajú na účely ďalšej analýzy s cieľom vylepšiť naše služby pre vás.
                            Čoskoro predstavíme novú platformu, zostaňte naladení !!
                          </Typography>
                        </Container>

                        <Container top='medium'>
                          <Typography variant='heading' size='medium' weight='medium'>
                            Ako sa zdieľajú vaše údaje?
                          </Typography>
                          <Typography size='medium'>Vaše údaje nezdieľame s inými stranami.</Typography>
                        </Container>

                        <Container top='medium'>
                          <Typography variant='heading' size='medium' weight='medium'>
                            Aký je náš právny základ pre spracovanie vašich údajov?
                          </Typography>
                          <Typography size='medium'>
                            Zákazník registráciou v systéme vyjadruje súhlas s podmienkami a zároveň dáva
                            poskytovateľovi súhlas s tým, že v súlade s § 11 zákona č. 122/2013 Z.z. o ochrane osobných
                            údajov a o zmene a doplnení niektorých zákonov v znení neskorších predpisov (ďalej len
                            „zákon o ochrane osobných údajov“) spracúval ich osobné údaje v rozsahu meno, priezvisko,
                            e-mail, adresa, univerzita a fakulta, užívateľské meno a heslo a že tieto osobné údaje boli
                            zahrnuté do databáz poskytovateľa na účely poskytovania služieb, analýz a ďalšieho výskumu.
                            Súhlas so spracovaním osobných údajov sa udeľuje na dobu trvania zmluvného vzťahu strán a na
                            dobu dvoch (2) rokov od jeho ukončenia.
                          </Typography>
                          <Typography size='medium'>
                            Pre registráciu, dotazníkov a údajov o účte je právnym základom plnenia zmluvy.
                          </Typography>
                          <Typography size='medium'>
                            Pre údaje o použití a údaje z kvízu je právnym základom súhlas.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            Vaše práva podľa GDPR
                          </Typography>
                          <Typography size='medium'>Vaše hlavné práva podľa GDPR sú:</Typography>
                          <Typography size='medium'>právo na prístup [pozri odsek 1];</Typography>
                          <Typography size='medium'>právo na opravu [pozri odsek 2];</Typography>
                          <Typography size='medium'>právo na vymazanie [pozri odsek 3];</Typography>
                          <Typography size='medium'>právo na obmedzenie spracovania [pozri odsek 4];</Typography>
                          <Typography size='medium'>
                            právo vzniesť námietku proti spracovaniu [pozri odsek 5];
                          </Typography>
                          <Typography size='medium'>právo na prenosnosť údajov [pozri odsek 8];</Typography>
                          <Typography size='medium'>právo sťažovať sa dozornému orgánu [pozri odsek 9];</Typography>
                          <Typography size='medium'>právo na odvolanie súhlasu [pozri odsek 10].</Typography>
                        </Container>
                        <Container top='small'>
                          <Typography size='medium'>
                            1. Máte právo na potvrdenie, či spracúvame vaše osobné údaje alebo nie, a kde to robíme,
                            prístup k osobným údajom spolu s určitými ďalšími informáciami. Tieto ďalšie informácie
                            zahŕňajú podrobnosti o účeloch spracúvania, kategóriách dotknutých osobných údajov a
                            príjemcoch osobných údajov. Poskytovanie práv a slobôd iných nie je dotknuté, dodáme vám
                            kópiu vašich osobných údajov. Prvá kópia bude poskytnutá bezplatne.
                          </Typography>
                          <Typography size='medium'>
                            2. Máte právo na opravu akýchkoľvek nepresných osobných údajov, ktoré sa vás týkajú, a s
                            prihliadnutím na účely spracovania, na doplnenie neúplných osobných údajov o vás.
                          </Typography>
                          <Typography size='medium'>
                            3. Za určitých okolností máte právo na vymazanie vašich osobných údajov bez zbytočného
                            odkladu. Medzi tieto okolnosti patrí: [osobné údaje už nie sú potrebné v súvislosti s
                            účelmi, na ktoré boli zhromaždené alebo inak spracované; odvoláte súhlas so spracovaním na
                            základe súhlasu; namietate proti spracovaniu podľa určitých pravidiel platných právnych
                            predpisov o ochrane údajov; spracovanie je na účely priameho marketingu; a osobné údaje boli
                            nezákonne spracované]. Právo na vymazanie však existuje. Všeobecné vylúčenia zahŕňajú
                            prípady, keď je spracúvanie nevyhnutné: na výkon práva na slobodu prejavu a informácií; za
                            splnenie zákonnej povinnosti; alebo na určenie, výkon alebo obranu právnych nárokov.
                          </Typography>
                          <Typography size='medium'>
                            4. Za určitých okolností máte právo obmedziť spracúvanie svojich osobných údajov. Za týchto
                            okolností: namietate presnosť osobných údajov; spracovanie je nezákonné, ale vy ste proti
                            vymazaniu; osobné údaje už nepotrebujeme na účely nášho spracovania, ale osobné údaje
                            požadujete na účely stanovenia, výkonu alebo obrany právnych nárokov; a vzniesli ste
                            námietku proti spracovaniu až do overenia tejto námietky. Tam, kde bolo spracovanie
                            obmedzené na tomto základe, pokračujeme v ukladaní vašich osobných údajov. Spracujeme to
                            však iba inak: s vašim súhlasom; na určenie, výkon alebo obranu právnych nárokov; na ochranu
                            práv inej fyzickej alebo právnickej osoby; alebo z dôvodov dôležitého verejného záujmu.
                          </Typography>
                          <Typography size='medium'>
                            5. Máte právo namietať proti nášmu spracúvaniu vašich osobných údajov z dôvodov týkajúcich
                            sa vašej konkrétnej situácie, ale iba v rozsahu, v akom je právnym základom spracúvania
                            nevyhnutnosť spracúvania na: splnenie úlohy vo verejnom záujme alebo pri výkone akejkoľvek
                            verejnej moci, ktorá nám je zverená; alebo účely oprávnených záujmov, ktoré sledujeme my
                            alebo tretia strana. Ak vznesiete takúto námietku, prestaneme spracúvať osobné údaje, pokiaľ
                            nepreukážeme presvedčivé oprávnené dôvody pre spracovanie, ktoré prevážia nad vašimi
                            záujmami, právami a slobodami, alebo pokiaľ spracovanie nie je na účely stanovenia, výkonu
                            alebo obrany právnych nárokov.
                          </Typography>
                          <Typography size='medium'>
                            6. Máte vždy právo namietať proti nášmu spracovaniu vašich osobných údajov na účely priameho
                            marketingu (vrátane profilovania na účely priameho marketingu). Ak vznesiete takúto
                            námietku, prestaneme na tento účel spracúvať vaše osobné údaje.
                          </Typography>
                          <Typography size='medium'>
                            7. Máte právo namietať proti nášmu spracovaniu vašich osobných údajov na účely vedeckého
                            alebo historického výskumu alebo štatistické účely z dôvodov týkajúcich sa vašej konkrétnej
                            situácie, pokiaľ nie je spracovanie nevyhnutné na splnenie úlohy vykonanej z dôvodov
                            verejného záujmu.
                          </Typography>
                          <Typography size='medium'>
                            8. Pokiaľ je právnym základom nášho spracúvania vašich osobných údajov:
                          </Typography>
                          <Typography size='medium'>a) súhlas; alebo</Typography>
                          <Typography size='medium'>
                            b) že spracovanie je nevyhnutné na splnenie zmluvy, ktorej ste zmluvnou stranou, alebo na
                            účely vykonania krokov na základe vašej žiadosti pred uzavretím zmluvy, a takéto spracovanie
                            sa vykonáva automatizovanými prostriedkami, máte právo dostávať od nás svoje osobné údaje v
                            štruktúrovanom, bežne používanom a strojovo čitateľnom formáte. Toto právo sa však
                            neuplatňuje, ak by malo nepriaznivý vplyv na práva a slobody ostatných.
                          </Typography>
                          <Typography size='medium'>
                            9. Ak sa domnievate, že naše spracovanie vašich osobných údajov porušuje zákony na ochranu
                            údajov, máte zákonné právo podať sťažnosť u dozorného orgánu zodpovedného za ochranu údajov.
                            Môžete tak urobiť v členskom štáte EÚ, v ktorom máte obvyklé bydlisko, miesto výkonu práce
                            alebo miesto údajného porušenia.
                          </Typography>
                          <Typography size='medium'>
                            10. Pokiaľ je právnym základom nášho spracúvania vašich osobných údajov súhlas, máte právo
                            tento súhlas kedykoľvek odvolať. Odstúpenie nebude mať vplyv na zákonnosť spracovania pred
                            výberom.
                          </Typography>
                        </Container>

                        <Container top='medium'>
                          <Typography variant='heading' size='medium' weight='medium'>
                            Uchovávanie a mazanie vašich osobných údajov
                          </Typography>
                          <Typography size='medium'>
                            Údaje ukladáme, kým už nie je potrebné poskytovať naše služby alebo produkty, alebo kým sa
                            neodstráni váš účet - podľa toho, čo nastane skôr. Ak svoj účet nechcete odstrániť, ale
                            chcete platformu dočasne prestať používať, môžete ho radšej deaktivovať. Ak chcete svoj účet
                            kedykoľvek vymazať, kontaktujte nás (pozri poslednú stránku tohto dokumentu).
                          </Typography>
                        </Container>

                        <Container top='medium'>
                          <Typography variant='heading' size='medium' weight='medium'>
                            Upozorňovanie vás na zmeny v pravidlách
                          </Typography>
                          <Typography size='medium'>
                            Keď aktualizujeme toto oznámenie o ochrane osobných údajov, zverejníme novú verziu na našom
                            webe.
                          </Typography>
                          <Typography size='medium'>
                            Budeme vás informovať o akýchkoľvek významných zmenách tohto oznámenia (e-mailom).
                          </Typography>
                        </Container>

                        <Container top='medium'>
                          <Typography variant='heading' size='medium' weight='medium'>
                            Ako nás kontaktovať
                          </Typography>
                          <Typography size='medium'>E-mail: privacy@quizy.sk</Typography>
                        </Container>

                        <Container top='large'>
                          <Typography variant='heading' size='medium' weight='medium'>
                            Pravidlá a podmienky súťaže
                          </Typography>
                          <Typography size='medium'>
                            Tieto pravidlá stanovujú podmienky účasti na súťaži o iPad Air + AirPods a spôsob a postup
                            pri vykonávaní činností potrebných na jej realizáciu ako aj podrobnosti o právach a
                            povinnostiach účastníkov.
                          </Typography>
                          <Typography size='medium'>
                            Termín konania súťaže je stanovený od dňa 2.11.2020 do 15.12.2020 kedy bude výherca
                            vyhlásený. Vylosovaní výhercova dostanú od organizátora súťaže informáciu o výhre najneskôr
                            do 3 pracovných dní od losovania.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            1. Kto sa môže zúčastniť súťaže?
                          </Typography>
                          <Typography size='medium'>
                            Každá osoba staršia ako 18 rokov bude automaticky zaradená do losovania. V prípade osoby
                            mladšej ako 18 rokov sa môže zúčastniť so súhlasom zákonného zástupcu.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            2. Ako a kedy bude vybraný víťaz?
                          </Typography>
                          <Typography size='medium'>
                            Použijeme nástroj, ktorý náhodne vyberie e-mailovú adresu od všetkých respondentov, ktorí
                            splnili obe nižšie uvedené požiadavky.
                          </Typography>
                          <Typography size='medium' weight='medium'>
                            Dve požiadavky sú -
                          </Typography>
                          <Typography size='medium'>1. Vyplňte informačný list</Typography>
                          <Typography size='medium'>2. Odpovedzte na všetky otázky v oboch kvízoch</Typography>
                          <Typography size='medium' weight='medium'>
                            Víťaz bude vybraný 15ho Decembra 2020.
                          </Typography>
                          <Typography size='medium'>
                            Nasleduje e-mail a výherca dostane e-mail so žiadosťou o adresu pre doručenie a telefónne
                            číslo, aby sme ho v prípade akýchkoľvek problémov vedeli kontaktovať.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            3. Výhra
                          </Typography>
                          <Typography size='medium'>
                            1x iPad Air + 1x AirPods. Výhra v Súťaži nie je vymáhateľná súdnou cestou. Výhru nie je
                            možné vymeniť za finančnú hotovosť.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography variant='heading' size='small' weight='medium'>
                            4. Spôsob odovzdania výhry
                          </Typography>
                          <Typography size='medium'>
                            Organizátor sa dohodne s výhercom na spôsobe odovzdania výhry, resp. organizátor zašle
                            výhercovi výhru poštou.
                          </Typography>
                          <Typography size='medium'>
                            Výherca Súťaže je povinný poskytnúť Organizátorovi požadovanú súčinnosť za účelom odovzdania
                            výhry.
                          </Typography>
                          <Typography size='medium'>
                            Organizátor je oprávnený pri kontaktovaní výhercu vyžiadať si od výhercu Súťaže osobné údaje
                            v rozsahu meno a priezvisko, e-mail, telefónne číslo, poštová adresa pre odovzdanie a
                            doručenie výhry, s čím výherca vyjadrí súhlas ich poskytnutím. Poskytnutie údajov výhercu je
                            dobrovoľné, ale ak ich výherca neposkytne, nie dodržaná súčinnosť na strane výhercu, ktorá
                            je nevyhnutná na odovzdanie výhry, a tým zaniká právo výhercu na výhru. Údaje výhercu budú
                            Organizátorom spracúvané najviac po dobu 6 mesiacov odo dňa ich poskytnutia. Poskytnutím
                            vyššie uvedených osobných údajov udeľuje výherca organizátorovi súhlas na spracovanie týchto
                            údajov. Výherca je zodpovedný za zadanie správnych údajov pre úspešné doručenie výhry. V
                            prípade, ak sa zásielka s výhrou vráti z adresy zadanej výhercom, Organizátor nie je povinný
                            vykonať opätovné doručenie výhry.
                          </Typography>
                          <Typography variant='heading' size='small' weight='medium'>
                            Strata nároku na výhru
                          </Typography>
                          <Typography size='medium'>
                            Výherca stráca nárok na výhru v prípade, že nesplní jednu z podmienok určených v
                            podmienkach. Taktiež, ak nebude reagovať v lehote 48 hodín na oznámenie o výhre, alebo
                            nesplní podmienku poskytnutia riadnej a úplnej súčinnosti pri odovzdaní výhry.
                          </Typography>
                          <Typography size='medium'>
                            V prípade akýchkoľvek pochybností o splnení pravidiel Súťaže rozhodne o ďalšom postupe
                            Organizátor, ktorý si súčasne vyhradzuje právo na zmenu týchto podmienok a pravidiel Súťaže
                            po jej vyhlásení.
                          </Typography>
                        </Container>
                        <Container top='small'>
                          <Typography size='medium'>V Bratislave, dňa 10.11.2020</Typography>
                        </Container>
                      </Modal>
                    </Container>

                    <Container fullWidth flex bottom='large'>
                      <Button type='submit' color='blue'>
                        {user ? 'Uložiť' : 'Poďme na to!'}
                      </Button>
                    </Container>
                  </S.UserForm>
                </Formik>
              </Container>
            </Grid.Item>

            <Grid.Item small={12} medium={6}>
              <S.BlackPaper>
                <Container flex fullWidth direction='column'>
                  <Container left='xsmall' right='medium'>
                    <Container top='xlarge'>
                      <Grid spacing='xsmall'>
                        <Grid.Item small={2} />
                        <Grid.Item small={10}>
                          <Typography variant='heading' size='large' color='white' weight='bold'>
                            PREČO BY SOM TO MAL/A VYPLNIŤ?
                          </Typography>
                        </Grid.Item>
                      </Grid>
                    </Container>
                    <Container flex fullWidth top='medium' direction='column'>
                      {generateInfoRow(
                        1,
                        'Spoznaj samého/ú seba 🧐',
                        'Pomocou našich kvízov ti pomôžeme lepšie porozumieť svojej osobnosti, čo môžeš využiť v mnohých oblastiach tvojho života - vzťahy, štúdium, kariéra, mentálne zdravie a oveľa viac.'
                      )}
                      {generateInfoRow(
                        2,
                        'Vyhraj nový iPad Air + AirPods',
                        'Aby sa ti lepšie učilo z domu a mohol/la si kukať Netflix v lockdowne - stačí odpovedať na všetky otázky v oboch kvízoch a si v hre 🤓'
                      )}
                      {generateInfoRow(3, 'Trvá to len 9min ⏱️')}
                      <Grid spacing='xsmall' alignItems='center'>
                        <Grid.Item small={6}>
                          <S.RotatedTypo variant='heading' color='white' align='center' weight='semibold'>
                            TAK POĎ DO TOHO !!
                          </S.RotatedTypo>
                        </Grid.Item>
                        <Grid.Item small={6}>
                          <S.Ipad src={iPad} alt='iPad air and airpods' />
                        </Grid.Item>
                      </Grid>
                    </Container>
                  </Container>
                </Container>
              </S.BlackPaper>
            </Grid.Item>
          </Grid>
        </Container>
      </Container>
    </Page>
  )
}
UserPage.displayName = 'UserPage'
export default withRouter(UserPage)
