import React from 'react'
import { Typography } from '@bite/sandy'

import { Page } from '../'

interface ErrorState {
  hasError: boolean
}

interface ErrorProps {
  children: React.ReactNode
}

class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  public readonly state = { hasError: false }
  static displayName = 'ErrorBoundary'

  public render() {
    const { children } = this.props

    if (this.state.hasError) {
      return (
        <Page title='Something went wrong' headerTitle='Quizzes' isLanding centered>
          <Typography variant='heading' size='xlarge' weight='semibold' align='center'>
            Oh no 😱
          </Typography>
          <Typography size='xlarge' align='center'>
            Something went wrong
          </Typography>
        </Page>
      )
    }
    return children
  }
}
export default ErrorBoundary
