import React from 'react'
import { Sandy } from '@bite/sandy'

import { ErrorBoundary } from './components'
import AppRouter from './router'

const App = () => (
  <Sandy theme='quizzesTheme'>
    <ErrorBoundary>
      <AppRouter />
    </ErrorBoundary>
  </Sandy>
)

App.displayName = 'App'
export default App
