import React, { ReactNode, HTMLAttributes } from 'react'

import * as S from './styles'

interface LinkProps extends HTMLAttributes<HTMLDivElement> {
  /** Content of Link component */
  children?: ReactNode
  /** Location of Link */
  to: string
}

export const Link = function Link({ to, children, className, style }: LinkProps) {
  return (
    <S.Link to={to} className={className} style={style}>
      {children}
    </S.Link>
  )
}

Link.displayName = 'Link'
export default Link
