import React from 'react'
import { Typography } from '@bite/sandy'

import { Page } from '../../components'

// TODO - Provide proper NotFoundPage (potentially can be merged with SomethingWentWrong from ErrorBoundary) including scenarios for UI tests
const NotFoundPage = () => (
  <Page title='Stránka sa nenašla' headerTitle='Quizy' isLanding centered hideMenu>
    <Typography variant='heading' size='xlarge' weight='semibold'>
      Ale nie 😱
    </Typography>
    <Typography size='xlarge'>Stránku, ktorú hľadáte sa nám nepodarilo nájsť</Typography>
  </Page>
)

NotFoundPage.displayName = 'NotFoundPage'
export default NotFoundPage
