/* eslint-disable max-lines-per-function */
/* eslint-disable max-params */
import React, { useState, useContext, MouseEvent } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { Container, Grid, Button, Modal, getBPHelpers, Quiz, useNotifications, Typography, Icon } from '@bite/sandy'
import { ThemeContext } from 'styled-components'

import { Page } from '../../components'
import { questions as longQuestions } from '../../_quizzes/long'
import { questions as shortQuestions } from '../../_quizzes/short'
import * as S from './styles'
import { getUser } from '../../lib/userStorage'

const HomePage = () => {
  const [isFirstOpen, setIsFirstOpen] = useState(false)
  const [isSecondOpen, setIsSecondOpen] = useState(false)
  const [isFirstDone, setIsFirstDone] = useState(window.sessionStorage.getItem('qa-bt-1') === 'done')
  const [isSecondDone, setIsSecondDone] = useState(window.sessionStorage.getItem('qa-bt-2') === 'done')
  const [isFetching, setIsFetching] = useState(false)
  const { showSuccess } = useNotifications()
  const themeContext = useContext(ThemeContext)
  const BPHelpers = getBPHelpers(themeContext.breakpoints)
  const modalSizeScreens = BPHelpers.useScreens<'medium' | 'large' | 'fullscreen'>()
  const modalSize = modalSizeScreens(
    {
      small: 'fullscreen',
      medium: 'medium'
    },
    'large'
  )

  const user: {
    firstName: string
    lastName: string
    email: string
    university?: string
  } = getUser()
  if (!user) {
    return <Redirect to='/user' />
  }

  const openQuiz = (e: MouseEvent<HTMLButtonElement & HTMLAnchorElement>, quizNum: number) => {
    e.stopPropagation()
    if (quizNum === 1) {
      setIsFirstOpen(true)
    } else {
      setIsSecondOpen(true)
    }
  }

  const closeQuiz = (quizNum: number, isComplete: boolean) => {
    if (isComplete) window.sessionStorage.setItem(`qa-bt-${quizNum}`, 'done')
    if (quizNum === 1) {
      setIsFirstOpen(false)
      if (isComplete) setIsFirstDone(true)
    } else {
      setIsSecondOpen(false)
      if (isComplete) setIsSecondDone(true)
    }
  }

  const submitQuiz = (
    formData: { [key: string]: string },
    version: 'short' | 'long',
    isComplete: boolean,
    quizNum: number
  ) => {
    if (isComplete) setIsFetching(true)

    const postData = {
      user: {
        name: user.firstName,
        lastName: user.lastName,
        email: user.email,
        university: user.university
      },
      meta: {
        type: version,
        isComplete: isComplete
      },
      answers: formData
    }

    return axios
      .post('https://service.bite.social/quiz.php', postData, {
        headers: { token: 'lkjaj023ujrioljlsfkj02ij3kdlkjfoij029jkjlksaaijkldafadas' }
      })
      .then(response => {
        setIsFetching(false)
        closeQuiz(quizNum, isComplete)
        if (isComplete) showSuccess('Ďakujeme vám za vyplnenie kvízu, čoskoro vás budeme kontaktovať s výsledkami!')
        return response
      })
      .catch(error => {
        if (isComplete) showSuccess('Ďakujeme vám za vyplnenie kvízu, čoskoro vás budeme kontaktovať s výsledkami!')
        setIsFetching(false)
        closeQuiz(quizNum, isComplete)
        return error
      })
  }

  return (
    <Page title='Domov' headerTitle='Quizy' isLanding>
      <Grid direction='row'>
        <Grid.Item small={12} large={6}>
          <S.PaperOne>
            <Container padded='small'>
              <S.QuizHeading variant='heading' weight='semibold' color='black'>
                Osobnostný
                <br />
                Kvíz A
              </S.QuizHeading>
              <Container fullWidth flex top='xlarge'>
                <Grid spacing={0} alignItems='center'>
                  <Grid.Item small={7}>
                    <Typography size='medium'>
                      Zisti kto naozaj si
                      <br /> v 19tich otázkach
                    </Typography>
                  </Grid.Item>
                  <Grid.Item small={1} />
                  <Grid.Item small={4}>
                    <Button
                      color='blue'
                      size='small'
                      disabled={isFirstDone}
                      onClick={(e: MouseEvent<HTMLButtonElement & HTMLAnchorElement>) => openQuiz(e, 1)}
                    >
                      {isFirstDone ? <Icon name='check' /> : 'Začať'}
                    </Button>
                  </Grid.Item>
                </Grid>
              </Container>
            </Container>

            <Modal
              hideCloseHandler
              noPadding
              size={modalSize}
              disableBackdropClick
              open={isFirstOpen}
              closeHandler={() => setIsFirstOpen(false)}
            >
              <Quiz
                isBlocked={isFetching}
                name='Osobnostný Kvíz A'
                questions={shortQuestions}
                color='orange'
                autoProgress
                onQuizSubmit={(val: { [key: string]: string }) => {
                  submitQuiz(val, 'short', true, 1)
                }}
                onQuizExit={(val: { [key: string]: string }) => {
                  submitQuiz(val, 'short', false, 1)
                  setIsFirstOpen(false)
                }}
                language={{
                  exit: 'Zavrieť',
                  submit: 'Odoslať',
                  exitPrompt: {
                    text: 'Naozaj chcete ukončiť test?',
                    positive: 'Áno',
                    negative: 'Nie'
                  }
                }}
              />
            </Modal>
          </S.PaperOne>
        </Grid.Item>
        <Grid.Item small={12} large={6}>
          <S.PaperTwo>
            <Container padded='small'>
              <S.QuizHeading variant='heading' weight='semibold' color='black'>
                Osobnostný
                <br />
                Kvíz B
              </S.QuizHeading>
              <Container fullWidth flex top='xlarge'>
                <Grid spacing={0} alignItems='center'>
                  <Grid.Item small={7}>
                    <Typography size='medium'>
                      Spoznaj lepšie
                      <br /> samého seba
                    </Typography>
                  </Grid.Item>
                  <Grid.Item small={1} />
                  <Grid.Item small={4}>
                    <Button
                      color='blue'
                      size='small'
                      disabled={isSecondDone}
                      onClick={(e: MouseEvent<HTMLButtonElement & HTMLAnchorElement>) => openQuiz(e, 2)}
                    >
                      {isSecondDone ? <Icon name='check' /> : 'Začať'}
                    </Button>
                  </Grid.Item>
                </Grid>
              </Container>

              <Modal
                hideCloseHandler
                noPadding
                size={modalSize}
                disableBackdropClick
                open={isSecondOpen}
                closeHandler={() => setIsSecondOpen(false)}
              >
                <Quiz
                  isBlocked={isFetching}
                  name='Osobnostný Kvíz B'
                  questions={longQuestions}
                  color='blue'
                  autoProgress
                  onQuizSubmit={(val: { [key: string]: string }) => {
                    submitQuiz(val, 'long', true, 2)
                  }}
                  onQuizExit={(val: { [key: string]: string }) => {
                    submitQuiz(val, 'long', false, 2)
                    setIsSecondOpen(false)
                  }}
                  language={{
                    exit: 'Zavrieť',
                    submit: 'Odoslať',
                    exitPrompt: {
                      text: 'Naozaj chcete ukončiť test?',
                      positive: 'Áno',
                      negative: 'Nie'
                    }
                  }}
                />
              </Modal>
            </Container>
          </S.PaperTwo>
        </Grid.Item>
      </Grid>
    </Page>
  )
}
HomePage.displayName = 'HomePage'
export default HomePage
