/* eslint-disable complexity */
// TODO - Add tests
import React, { ReactNode, HTMLAttributes, forwardRef, useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Typography, Container, Grid, getBPHelpers, Icon } from '@bite/sandy'
import { ThemeContext } from 'styled-components'

import { Link } from '../'
import * as S from './styles'

interface PageProps extends RouteComponentProps, HTMLAttributes<HTMLDivElement> {
  /** Content of Page component */
  children?: ReactNode
  /** Centers content vertically */
  centered?: boolean
  /** HTML title using our pattern defined at Layout component */
  title: string
  /** Heading visible at the top of the page */
  headerTitle?: string
  /** Description below headerTitle (visible only with combination with isLanding) */
  headerDesc?: string
  /** Name of the page visible at the top of content section */
  pageTitle?: string
  /** Hides main menu next to headerTitle */
  hideMenu?: boolean
  /** Renders different page header  */
  isLanding?: boolean
}

export const Page = forwardRef<HTMLDivElement, PageProps>(function Page(
  { children, title, centered, headerTitle, pageTitle, isLanding, headerDesc, hideMenu, className, style, ...rest },
  ref
) {
  const themeContext = useContext(ThemeContext)
  const BPHelpers = getBPHelpers(themeContext.breakpoints)
  const sizesScreens = BPHelpers.useScreens<'flex-start' | 'flex-end' | 'center'>()
  const spacesScreens = BPHelpers.useScreens<'xsmall' | 'small' | 'medium' | 'large' | 'xlarge'>()
  const headerSpacing = spacesScreens(
    {
      small: 'small'
    },
    'xlarge'
  )
  const footerSpacing = spacesScreens(
    {
      small: 'large'
    },
    undefined
  )

  const footerLinkAlignment = sizesScreens(
    {
      small: 'center',
      medium: 'flex-start'
    },
    'flex-start'
  )

  const footerGridJustifyContent = sizesScreens(
    {
      small: 'center',
      medium: 'flex-end'
    },
    'flex-end'
  )

  const menuItems = [
    {
      to: '/user',
      title: 'Užívateľská zóna'
    }
  ]

  const renderMenu = menuItems.map((item, index) => (
    <Link to={item.to} key={`qp-mi-${index}`}>
      <Typography as='span' variant='heading' size='small' weight='semibold'>
        {item.title}
      </Typography>
    </Link>
  ))

  const renderBigHeader = (
    <Container
      top={headerSpacing}
      bottom={headerSpacing}
      direction='column'
      flex
      fullWidth
      alignItems='flex-start'
      justifyContent='center'
    >
      <Grid
        justifyContent={headerTitle || headerDesc ? (hideMenu ? 'flex-start' : 'space-between') : 'flex-end'}
        alignItems='center'
      >
        <Grid.Item>
          {headerTitle && (
            <Link to='/'>
              <Typography as='span' variant='heading' size='xlarge' weight='semibold'>
                {headerTitle}
              </Typography>
            </Link>
          )}
          {headerDesc && (
            <Container>
              <Typography as='span' size='medium'>
                {headerDesc}
              </Typography>
            </Container>
          )}
        </Grid.Item>
        {!hideMenu && <Grid.Item>{renderMenu}</Grid.Item>}
      </Grid>
    </Container>
  )

  const renderSmallHeader = (
    <Container flex fullWidth direction='column'>
      <Container
        top={headerSpacing}
        bottom={headerSpacing}
        direction='column'
        flex
        fullWidth
        alignItems='flex-start'
        justifyContent='center'
      >
        <Grid
          justifyContent={headerTitle ? (hideMenu ? 'flex-start' : 'space-between') : 'flex-end'}
          alignItems='center'
        >
          <Grid.Item>
            {headerTitle && (
              <Link to='/'>
                <Typography as='span' variant='heading' size='small' weight='semibold'>
                  {headerTitle}
                </Typography>
              </Link>
            )}
          </Grid.Item>
          {!hideMenu && <Grid.Item>{renderMenu}</Grid.Item>}
        </Grid>
      </Container>
      {pageTitle && (
        <Container bottom='large'>
          <S.PageTitle variant='heading' weight='semibold'>
            {pageTitle}
          </S.PageTitle>
        </Container>
      )}
    </Container>
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderFooter = (
    <Container top='xlarge' direction='row' flex fullWidth justifyContent='center'>
      <Grid direction='row-reverse' alignItems='center' justifyContent={footerGridJustifyContent}>
        <Grid.Item small={12} medium={6} style={{ flexGrow: 1, maxWidth: '100%' }}>
          <Container flex justifyContent='space-evenly'>
            <Link to='#'>
              <Icon name='instagram' size='large' />
            </Link>
            <Link to='#'>
              <Icon name='facebook' size='large' />
            </Link>
            <Link to='#'>
              <Icon name='linkedin' size='large' />
            </Link>
          </Container>
        </Grid.Item>
        <Grid.Item>
          <Container top={footerSpacing} bottom={footerSpacing}>
            <Container bottom='small' justifyContent={footerLinkAlignment} flex fullWidth>
              <Link to='#'>
                <Typography as='span' variant='heading' size='small' weight='semibold'>
                  About
                </Typography>
              </Link>
            </Container>
            <Container bottom='small' justifyContent={footerLinkAlignment} flex fullWidth>
              <Link to='#'>
                <Typography as='span' variant='heading' size='small' weight='semibold'>
                  Our Promise
                </Typography>
              </Link>
            </Container>
            <Container bottom='small' justifyContent={footerLinkAlignment} flex fullWidth>
              <Link to='#'>
                <Typography as='span' variant='heading' size='small' weight='semibold'>
                  The Science
                </Typography>
              </Link>
            </Container>
            <Container bottom='small' justifyContent={footerLinkAlignment} flex fullWidth>
              <Link to='#'>
                <Typography as='span' variant='heading' size='small' weight='semibold'>
                  Privacy Policy
                </Typography>
              </Link>
            </Container>
          </Container>
        </Grid.Item>
      </Grid>
    </Container>
  )
  return (
    <S.PageWrapper
      flex
      fullWidth
      direction='column'
      alignItems='center'
      ref={ref}
      className={className}
      style={style}
      {...rest}
    >
      <Helmet title={title} />
      <S.PageContent alignItems={centered ? 'center' : 'flex-start'} flex fullWidth>
        {isLanding ? renderBigHeader : renderSmallHeader}
        <Container bottom='small' flex fullWidth direction='column' alignItems={centered ? 'center' : 'flex-start'}>
          {children}
        </Container>
        {/* {renderFooter} */}
      </S.PageContent>
    </S.PageWrapper>
  )
})

Page.defaultProps = {
  centered: false,
  isLanding: false,
  hideMenu: false
}

Page.displayName = 'Page'
export default withRouter(Page)
