/* eslint-disable max-lines */
const commonQuestionProps = {
  scaleExplanationLeft: 'Neplatí to\npre mňa vôbec',
  scaleExplanationRight: 'Platí to\npre mňa úplne',
  answers: [0, 1, 2, 3, 4]
}

export const questions = [
  {
    id: 'q1',
    question: 'Som spoločenský/á, rád/a trávim čas s inými ľuďmi',
    ...commonQuestionProps
  },
  {
    id: 'q2',
    question: 'Som súcitný/á',
    ...commonQuestionProps
  },
  {
    id: 'q3',
    question: 'Mám sklon byť chaotický/á',
    ...commonQuestionProps
  },
  {
    id: 'q4',
    question: 'Som uvoľnený/á, dobre zvládam stres',
    ...commonQuestionProps
  },
  {
    id: 'q5',
    question: 'Mám málo umeleckých záujmov',
    ...commonQuestionProps
  },
  {
    id: 'q6',
    question: 'Mám asertívnu osobnosť, vyjadrujem svoje názory',
    ...commonQuestionProps
  },
  {
    id: 'q7',
    question: 'Som úctivý/á, správam sa kiným s rešpektom',
    ...commonQuestionProps
  },
  {
    id: 'q8',
    question: 'Mám sklon byť lenivý/á',
    ...commonQuestionProps
  },
  {
    id: 'q9',
    question: 'Ostávam optimistický/á napriek prekážkam',
    ...commonQuestionProps
  },
  {
    id: 'q10',
    question: 'Som zvedavý/á na mnoho rozličných vecí',
    ...commonQuestionProps
  },
  {
    id: 'q11',
    question: 'Som málokedy pre niečo zapálený/á',
    ...commonQuestionProps
  },
  {
    id: 'q12',
    question: 'Mám sklon hľadať chyby v ostatných',
    ...commonQuestionProps
  },
  {
    id: 'q13',
    question: 'Som spoľahlivý/á a dôsledný/á',
    ...commonQuestionProps
  },
  {
    id: 'q14',
    question: 'Som náladový/á, mávam výkyvy nálady',
    ...commonQuestionProps
  },
  {
    id: 'q15',
    question: 'Som vynaliezavý/á, hľadám inovatívne spôsoby ako robiť veci',
    ...commonQuestionProps
  },
  {
    id: 'q16',
    question: 'Mám sklon byť tichý/á',
    ...commonQuestionProps
  },
  {
    id: 'q17',
    question: 'Cítim málo pochopenia a súcitu k iným',
    ...commonQuestionProps
  },
  {
    id: 'q18',
    question: 'Som systematický/á, rád/a udržiavam veci v poriadku',
    ...commonQuestionProps
  },
  {
    id: 'q19',
    question: 'Bývam napätý/á',
    ...commonQuestionProps
  },
  {
    id: 'q20',
    question: 'Som očarený/á umením, hudbou alebo literatúrou',
    ...commonQuestionProps
  },
  {
    id: 'q21',
    question: 'Som dominantný/á, správam sa ako vodca',
    ...commonQuestionProps
  },
  {
    id: 'q22',
    question: 'Vyvolávam spory s inými',
    ...commonQuestionProps
  },
  {
    id: 'q23',
    question: 'Robí mi problém začať nejakú úlohu',
    ...commonQuestionProps
  },
  {
    id: 'q24',
    question: 'Cítim sa sebaistý/á, som spokojný/á so sebou',
    ...commonQuestionProps
  },
  {
    id: 'q25',
    question: 'Vyhýbam sa intelektuálnym, filozofickým diskusiám',
    ...commonQuestionProps
  },
  {
    id: 'q26',
    question: 'Som menej angažovaný než ostatní ľudia',
    ...commonQuestionProps
  },
  {
    id: 'q27',
    question: 'Mám v povahe odpúšťať',
    ...commonQuestionProps
  },
  {
    id: 'q28',
    question: 'Bývam nepozorný/á',
    ...commonQuestionProps
  },
  {
    id: 'q29',
    question: 'Som emocionálne stabilný/á, neznepokojím sa tak ľahko',
    ...commonQuestionProps
  },
  {
    id: 'q30',
    question: 'Som málo tvorivý/á',
    ...commonQuestionProps
  },
  {
    id: 'q31',
    question: 'Niekedy som hanblivý/á, uzavretý/á',
    ...commonQuestionProps
  },
  {
    id: 'q32',
    question: 'Som nápomocný/á a nezištný/á k iným',
    ...commonQuestionProps
  },
  {
    id: 'q33',
    question: 'Udržujem svoje veci usporiadané a zorganizované',
    ...commonQuestionProps
  },
  {
    id: 'q34',
    question: 'Často sa znepokojujem',
    ...commonQuestionProps
  },
  {
    id: 'q35',
    question: 'Cením si umenie a krásu',
    ...commonQuestionProps
  },
  {
    id: 'q36',
    question: 'Pokladám za ťažké ovplyvňovať ľudí',
    ...commonQuestionProps
  },
  {
    id: 'q37',
    question: 'Niekedy som hrubý/á k iným',
    ...commonQuestionProps
  },
  {
    id: 'q38',
    question: 'Som efektívny/a, veci dokončím',
    ...commonQuestionProps
  },
  {
    id: 'q39',
    question: 'Často sa cítim smutný/á',
    ...commonQuestionProps
  },
  {
    id: 'q40',
    question: 'Rozmýšľam komplexne, do hĺbky',
    ...commonQuestionProps
  },
  {
    id: 'q41',
    question: 'Som plný/á energie',
    ...commonQuestionProps
  },
  {
    id: 'q42',
    question: 'Som podozrievavý/á voči úmyslom iných',
    ...commonQuestionProps
  },
  {
    id: 'q43',
    question: 'Som dôveryhodný/á, dá sa na mňa vždy spoľahnúť',
    ...commonQuestionProps
  },
  {
    id: 'q44',
    question: 'Mám svoje emócie pod kontrolou',
    ...commonQuestionProps
  },
  {
    id: 'q45',
    question: 'Mám problém predstavovať si veci',
    ...commonQuestionProps
  },
  {
    id: 'q46',
    question: 'Som zhovorčivý/á',
    ...commonQuestionProps
  },
  {
    id: 'q47',
    question: 'Som chladný/á a ľahostajný/á',
    ...commonQuestionProps
  },
  {
    id: 'q48',
    question: 'Zanechávam neporiadok, neupratujem',
    ...commonQuestionProps
  },
  {
    id: 'q49',
    question: 'Zriedkakedy cítim úzkosť alebo obavy',
    ...commonQuestionProps
  },
  {
    id: 'q50',
    question: 'Považujem poéziu a divadlo za nudné',
    ...commonQuestionProps
  },
  {
    id: 'q51',
    question: 'Preferujem, aby iní robili rozhodnutia',
    ...commonQuestionProps
  },
  {
    id: 'q52',
    question: 'Som slušný/á, zdvorilý/á voči iným',
    ...commonQuestionProps
  },
  {
    id: 'q53',
    question: 'Som vytrvalý/á, pracujem pokiaľ nie je úloha dokončená',
    ...commonQuestionProps
  },
  {
    id: 'q54',
    question: 'Mám sklon cítiť sa skľúčený/á, skleslý/á',
    ...commonQuestionProps
  },
  {
    id: 'q55',
    question: 'Mám malý záujem o abstraktné myšlienky',
    ...commonQuestionProps
  },
  {
    id: 'q56',
    question: 'Prejavujem veľa nadšenia',
    ...commonQuestionProps
  },
  {
    id: 'q57',
    question: 'Myslím si o ľuďoch to najlepšie',
    ...commonQuestionProps
  },
  {
    id: 'q58',
    question: 'Niekedy sa správam nezodpovedne',
    ...commonQuestionProps
  },
  {
    id: 'q59',
    question: 'Som temperamentný/á, ľahko sa rozčúlim',
    ...commonQuestionProps
  },
  {
    id: 'q60',
    question: 'Som originálny/a, prichádzam s novými nápadmi',
    ...commonQuestionProps
  }
]
