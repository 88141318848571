/* eslint-disable max-lines */

import img1 from '../assets/img/qi/1.png'
import img2 from '../assets/img/qi/2.png'
import img3 from '../assets/img/qi/3.png'
import img4 from '../assets/img/qi/4.png'
import img5 from '../assets/img/qi/5.png'
import img6 from '../assets/img/qi/6.png'
import img7 from '../assets/img/qi/7.png'
import img8 from '../assets/img/qi/8.png'
import img9 from '../assets/img/qi/9.png'
import img10 from '../assets/img/qi/10.png'
import img11 from '../assets/img/qi/11.png'
import img12 from '../assets/img/qi/12.png'
import img13 from '../assets/img/qi/13.png'
import img14 from '../assets/img/qi/14.png'
import img15 from '../assets/img/qi/15.png'
import img16 from '../assets/img/qi/16.png'
import img17 from '../assets/img/qi/17.png'
import img18 from '../assets/img/qi/18.png'
import img19 from '../assets/img/qi/19.png'

const commonQuestionProps = {
  answers: [0, 1, 2, 3, 4]
}

export const questions = [
  {
    id: 'q1',
    question: 'Keď som s novými ľuďmi...',
    scaleExplanationLeft: 'Skôr počúvam',
    scaleExplanationRight: 'Skôr rozprávam a zabávam',
    image: img1,
    ...commonQuestionProps
  },
  {
    id: 'q2',
    question: 'Keď v skupine rozhodujeme, čo budeme robiť...',
    scaleExplanationLeft: 'Nechám to\nna iných',
    scaleExplanationRight: 'Presadzujem moje návrhy',
    image: img2,
    ...commonQuestionProps
  },
  {
    id: 'q3',
    question: 'O úmysloch väčšiny ľudí...',
    scaleExplanationLeft: 'Si myslím\nto najlepšie',
    scaleExplanationRight: 'Pochybujem a\nnedôverujem im',
    image: img3,
    ...commonQuestionProps
  },
  {
    id: 'q4',
    question: 'Spory riešim...',
    scaleExplanationLeft: 'Priamo a nekompromisne',
    scaleExplanationRight: 'Zmierlivo a\ns rešpektom',
    image: img4,
    ...commonQuestionProps
  },
  {
    id: 'q5',
    question: 'Ak ma niekto sklame...',
    scaleExplanationLeft: 'Dokážem odpúšťať',
    scaleExplanationRight: 'Ďalej ma už nezaujíma',
    image: img5,
    ...commonQuestionProps
  },
  {
    id: 'q6',
    question: 'Keď zažijem niečo negatívne...',
    scaleExplanationLeft: 'Dlhšie som skľúčený/á\na trápi ma to',
    scaleExplanationRight: 'Otrasiem sa\na idem ďalej',
    image: img6,
    ...commonQuestionProps
  },
  {
    id: 'q7',
    question: 'Keď je niekto v zložitej situácii...',
    scaleExplanationLeft: 'Nechám ho\nnech si to vyrieši',
    scaleExplanationRight: 'Som starostlivý/á\na pomôžem mu',
    image: img7,
    ...commonQuestionProps
  },
  {
    id: 'q8',
    question: 'Moje charakteristiky sú skôr..',
    scaleExplanationLeft: 'Zvedavosť\na tvorivosť',
    scaleExplanationRight: 'Praktickosť\na realizmus',
    image: img8,
    ...commonQuestionProps
  },
  {
    id: 'q9',
    question: 'Väčšinou premýšľam o veciach... ',
    scaleExplanationLeft: 'Prakticky\na jednoducho',
    scaleExplanationRight: 'Komplexne\na abstraktne',
    image: img9,
    ...commonQuestionProps
  },
  {
    id: 'q10',
    question: 'K náročným situáciám pristupujem...',
    scaleExplanationLeft: 'Optimisticky\na sebaisto',
    scaleExplanationRight: 'Opatrne\na s obavami',
    image: img10,
    ...commonQuestionProps
  },
  {
    id: 'q11',
    question: 'Pre môj temperament je typické...',
    scaleExplanationLeft: 'Zostať pokojný/á',
    scaleExplanationRight: 'Ľahko sa rozrušiť',
    image: img11,
    ...commonQuestionProps
  },
  {
    id: 'q12',
    question: 'Keď ma čaká náročná situácia...',
    scaleExplanationLeft: 'Spoľahnem sa\nna improvizáciu',
    scaleExplanationRight: 'Snažím sa\nperfektne pripraviť',
    image: img12,
    ...commonQuestionProps
  },
  {
    id: 'q13',
    question: 'K riešeniu úloh pristupujem...',
    scaleExplanationLeft: 'Energicky\na s nadšením',
    scaleExplanationRight: 'V tichosti\na rozvážne',
    image: img13,
    ...commonQuestionProps
  },
  {
    id: 'q14',
    question: 'Môj prístup k práci je skôr...',
    scaleExplanationLeft: 'Vytrvalý\na dôsledný',
    scaleExplanationRight: 'Bezstarostný\na spontánny',
    image: img14,
    ...commonQuestionProps
  },
  {
    id: 'q15',
    question: 'Častejšie riešim veci...',
    scaleExplanationLeft: 'Dôveryhodne\na spoľahlivo',
    scaleExplanationRight: 'Nepozorne\na nezodpovedne',
    image: img15,
    ...commonQuestionProps
  },
  {
    id: 'q16',
    question: 'Moju dôveru si získajú skôr riešenia...',
    scaleExplanationLeft: 'Konzervatívne\na racionálne',
    scaleExplanationRight: 'Liberálne\na inovatívne',
    image: img16,
    ...commonQuestionProps
  },
  {
    id: 'q17',
    question: 'Vo chvíľach voľna...',
    scaleExplanationLeft: 'Som rád/rada\nsám/sama',
    scaleExplanationRight: 'Vyhľadávam\nspoločnosť',
    image: img17,
    ...commonQuestionProps
  },
  {
    id: 'q18',
    question: 'Vo svojich veciach...',
    scaleExplanationLeft: 'Mám často\nneporiadok',
    scaleExplanationRight: 'Mám skoro\nvždy poriadok',
    image: img18,
    ...commonQuestionProps
  },
  {
    id: 'q19',
    question: 'Umenie a krásne veci...',
    scaleExplanationLeft: 'Ma často\nnadchnú',
    scaleExplanationRight: 'Ma dokážu\nnudiť',
    image: img19,
    ...commonQuestionProps
  }
]
