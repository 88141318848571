import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

import { PageLoader } from '../'
import * as S from './styles'

interface LayoutProps {
  children?: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const viewer = { data: { userId: 'userId' } }
  const loading = viewer == null

  return (
    <S.Wrapper>
      <Helmet defaultTitle='Home | Quizy' titleTemplate='%s | Quizy' />
      {loading && <PageLoader data-testid='layout-loader' />}
      <S.ContentMask active={loading}>
        <S.Content>{children}</S.Content>
      </S.ContentMask>
    </S.Wrapper>
  )
}

Layout.defaultProps = {}

Layout.displayName = 'Layout'
export default Layout
