import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'

import setMaximumScaleOnIOSWebkit from './lib/setMaximumScaleOnIOSWebkit'
import App from './App'

setMaximumScaleOnIOSWebkit()

ReactDOM.render(<App />, document.getElementById('root'))
