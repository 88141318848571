import styled from 'styled-components'
import { Form } from 'formik'
import { Paper, Typography, getBPHelpers } from '@bite/sandy'

const UserForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const BlackPaper = styled(Paper)`
  background: ${props => props.theme.colors.black.default};
`

const OrangePaper = styled(Paper)`
  background: ${props => props.theme.colors.orange.default};
`

const RotatedTypo = styled(Typography)`
  transform: rotate(-21deg);
  font-size: 45px;
  top: -24px;
  width: 140%;
  position: relative;

  ${props => getBPHelpers(props.theme.breakpoints).screens('medium')} {
    font-size: 40px;
    top: 0;
    width: 200%;
  }

  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    margin-top: 45px;
    font-size: 40px;
  }
`

const Ipad = styled.img`
  width: 150%;
`

export { UserForm, BlackPaper, OrangePaper, RotatedTypo, Ipad }
