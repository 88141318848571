import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Delay } from '@bite/sandy'

import { ErrorBoundary, Layout, PageLoader, Route } from './components'
import { NotFoundPage } from './pages'

const UnderConstructionPage = lazy(() => import('./pages/UnderConstruction' /* webpackChunkName: "home" */))

// TODO - PageViewTracker

const AppRouter = () => {
  return (
    <Router>
      <ErrorBoundary>
        <Layout>
          <Suspense
            fallback={
              <Delay ms={250}>
                <PageLoader />
              </Delay>
            }
          >
            <Switch>
              <Route path='/' exact component={UnderConstructionPage} />

              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </Layout>
      </ErrorBoundary>
    </Router>
  )
}

AppRouter.displayName = 'AppRouter'
export default AppRouter
