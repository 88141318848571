import styled from 'styled-components'
import { Paper, Typography, getBPHelpers } from '@bite/sandy'

import quizOne from '../../assets/img/quiz-one.png'
import quizThree from '../../assets/img/quiz-three.png'

const QuizHeading = styled(Typography)`
  font-size: 39px;

  ${props => getBPHelpers(props.theme.breakpoints).screens('small')} {
    font-size: 28px;
  }
`

const PaperOne = styled(Paper)`
  background: ${props => props.theme.colors.orange.default};
  background-image: url(${quizOne});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 94% 50%;
`

const PaperTwo = styled(Paper)`
  background: ${props => props.theme.colors['light-grey'].default};
  background-image: url(${quizThree});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 10px;
`

export { QuizHeading, PaperOne, PaperTwo }
