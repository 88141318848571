import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  margin: 0 auto;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ContentMask = styled.div<{ active: boolean }>`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-grow: 1;
  justify-content: center;
  overflow: hidden;
  /* stylelint-disable value-keyword-case */
  ${props =>
    props.active &&
    css`
      display: none;
    `} /* stylelint-enable value-keyword-case */
`

export { Wrapper, Content, ContentMask }
